import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import tw from 'twin.macro';
import styled, { keyframes } from 'styled-components';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import emailjs from '@emailjs/browser';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px dashed rgba(113, 128, 150, 1); /* Light grey */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const Spinner = () => {
  return <Loader />;
};

const SubmitButton = styled(PrimaryButtonBase)`
  ${tw`inline-block mt-8 flex justify-center items-center`}
`;
const FormContainer = styled.div`
  ${tw`p-4 md:p-6 lg:p-10 bg-white rounded-lg shadow-xl`}
  /* Additional styles for more customization */
  background-color: rgba(255, 255, 255, 0.25); /* Slightly transparent background */
  backdrop-filter: blur(8px); /* For a frosted glass effect */
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1),
    0 20px 40px -5px rgba(0, 0, 0, 0.04);
`;

const StyledForm = tw.form`mt-4 md:mt-8 text-sm flex flex-col`;
const Heading = tw.h1`font-bold text-2xl md:text-2xl lg:text-2xl xl:text-3xl text-white leading-tight mb-6`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 p-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

export default function MyForm() {
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      plate: '',
      number: '',
    },
  });
  const swedishPlatePattern = /^[A-Z]{3}\s?\d{3}$/;

  const onSubmit = (data) => {
    console.log('DATA: ', data);
    setIsLoading(true);
    emailjs
      .send('service_zptea1u', 'template_kqsfk8p', data, 'ZsOLYmJMqzIy8-818')
      .then(
        (response) => {
          setStatus(response?.text);
          reset({
            email: '',
            plate: '',
            number: '',
          });
          setIsLoading(false);
          setSuccess(true);
        },
        (error) => {
          console.log('#### ERROR SENDING MESSAGE LANDING PAGE: ', error);
          setStatus(error);
        },
      );
  };

  return (
    <FormContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Heading>Värdera/Sälja bil</Heading>
        <Notification>
          Är du nyfiken på vad din bil är värd? Vi värderar din bil inför
          försäljning och bilbyte
        </Notification>
        <Input
          {...register('email', { required: true })}
          type="email"
          placeholder="Epost..."
          disabled={success}
          name="email"
        />
        <p style={{ color: 'red', marginTop: '6px' }}>
          {errors.email && errors.email.message}
        </p>

        <Input
          {...register('plate', {
            required: 'Bilens registrerings nummer måste vara korrekt',
            pattern: {
              value: swedishPlatePattern,
              message: 'Fel format registreringsnummer, exempel: XYZ123',
            },
          })}
          disabled={success}
          type="text"
          placeholder="Ange registreringsnummer..."
          name="plate"
        />
        <p style={{ color: 'red', marginTop: '6px' }}>
          {errors.plate && errors.plate.message}
        </p>

        <Input
          {...register('number', {
            required: true,
            pattern: {
              // value: swedishPhonePattern,
              message: 'Kräver ett giltigt telefon nummer',
            },
          })}
          disabled={success}
          type="tel"
          placeholder="Telefonnummer..."
          name="number"
        />
        <p style={{ color: 'red', marginTop: '6px' }}>
          {errors.number && errors.number.message}
        </p>
        <SubmitButton disabled={success} type="submit">
          {isLoading ? <Spinner /> : success ? 'Vi hör av oss!' : 'Skicka'}
        </SubmitButton>
      </StyledForm>
    </FormContainer>
  );
}
