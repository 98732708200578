import React, { useContext, useEffect, useRef, useState } from 'react';

import styled, { css } from 'styled-components';
import {
  Carousel as BaseCarousel,
  Col,
  Row,
  Modal as BaseModal,
  Card,
  Descriptions,
  Button,
  Collapse,
} from 'antd';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { PageHeader as BasePageHeader } from '@ant-design/pro-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { CarContext } from '../../context/carcontext';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import HeaderBase, {
  NavLink as NavLinkBase,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
} from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import { Content2Xl } from '../misc/Layouts';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import { PrimaryButton as PrimaryButtonBase } from '../misc/Buttons';
import {
  GiCarWheel,
  GiFuelTank,
  GiGearStickPattern,
  GiPaintBrush,
  GiSpeedometer,
  GiSteeringWheel,
} from 'react-icons/gi';
import { VscMilestone } from 'react-icons/vsc';
import { IoCalendarOutline } from 'react-icons/io5';
import { AiOutlineCar, AiOutlineSetting } from 'react-icons/ai';
import { MdModelTraining } from 'react-icons/md';
import { GiCarousel } from 'react-icons/gi';
import { DingtalkOutlined } from '@ant-design/icons';

const PageHeader = styled(BasePageHeader)`
  margin: 1rem 0;
`;
const HeaderContainer = tw.div`max-w-none -mt-8 py-8 -mx-8 px-8 bg-gray-600`;
const Header = tw(HeaderBase)``;
const StyledRow = styled(Row)`
  gap: 2rem;
`;

const ThumbnailsRow = styled(StyledRow)`
  @media (max-width: 768px) {
    display: none;
  }
`;
const NavLink = tw(
  NavLinkBase,
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const PrimaryLink = tw(
  PrimaryLinkBase,
)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-300`;
// const CarouselContainer = styled(Col)`
//   position: relative;
// `;

const CarouselContainer = styled(Col)`
  position: relative;
  max-height: 640px;
  overflow: hidden;
  @media (max-width: 640px) {
    height: auto;
  }
`;

const LeftButton = styled(Button)`
  ${tw`rounded-full`}
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-left: 1rem;
  padding: 0;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  width: 36px;
  height: 36px;

  &&:hover {
    color: white;
    border: rgba(255, 255, 255, 0.5) 1px solid;
  }

  @media (max-width: 768px) {
    margin-left: 0.5rem;
    width: 32px;
    height: 32px;
  }
`;

const RightButton = styled(Button)`
  ${tw`rounded-full`}
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  margin-right: 1rem;
  font-size: 24px;
  padding: 0;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  width: 36px;
  height: 36px;

  &&:hover {
    color: white;
    border: rgba(255, 255, 255, 0.5) 1px solid;
  }

  @media (max-width: 768px) {
    margin-right: 0.5rem;
    width: 32px;
    height: 32px;
  }
`;
const Carousel = styled(BaseCarousel)`
  .slick-slide > div {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .slick-slide.slick-active > div {
    opacity: 1;
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

  .slick-dots li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 4px;
    background-color: #fff;
    border-radius: 2px;
    margin: 0 4px;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
  }

  .slick-dots li.slick-active {
    opacity: 1;
  }

  .slick-dots {
    display: none !important;
  }
`;

const Thumbnails = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;
  max-height: 640px;
  gap: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 640px) {
    max-height: unset;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
  }

  img {
    margin-bottom: 10px;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
`;

const StyledCard = styled(Card)`
  ${tw`w-full max-w-screen-lg mx-auto my-8`}
`;

const CarouselImageContainer = styled.div(({ active }) => [
  tw`relative`,
  active && tw`opacity-100`,
  !active && tw`opacity-0 transition-opacity duration-500 ease-in-out`,
]);

const CarouselImage = tw.img`w-full h-auto`;

const Thumbnail = styled.img(({ active }) => [
  tw`w-full h-16 object-cover cursor-pointer transition-all duration-300 ease-in-out my-1 mx-1 relative`,
  active ? tw`[transform:scale(1.1)] shadow-md` : tw`[transform:scale(1)]`,
]);

const CarouselInfoContainer = styled(Col)``;

const Title = styled.h1`
  font-size: 15px;
  color: #62605f;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Subtitle = styled.h2`
  font-size: 15px;
  color: #243e63;
  font-weight: bold;
`;

const PriceTag = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const StyledDivider = tw.hr`border-none h-[2px] bg-gray-400 my-5`;
const ContactUsContainer = styled.div`
  ${tw`flex flex-col items-center justify-center shadow-lg shadow-xl my-4 px-8 max-w-screen-lg mx-auto`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const Modal = styled(BaseModal)`
  .full-screen-modal .ant-modal-content {
    height: 100vh;
  }
`;

const NAVLINKS = [
  <NavLinks key={1}>
    <NavLink href="/">Hem</NavLink>
    <NavLink href="/cars">Köpa bil</NavLink>
    <NavLink href="/contact">Sälja bil</NavLink>
    <PrimaryLink href="/contact">Kontakt</PrimaryLink>
  </NavLinks>,
];
const { Panel } = Collapse;

const StyledListContainer = styled.div`
  ${tw`w-full max-w-screen-lg mx-auto my-8`}
`;

const StyledList = styled.ul`
  ${tw`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pr-4`};
`;

const StyledBulletList = styled.ul`
  ${tw`list-disc list-inside grid grid-cols-2`};

  li {
    font-size: 15px;
    color: #62605f;
  }
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    font-size: 18px;
  }
`;

const CarDetails = ({ formatPrice }) => {
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const cars = useContext(CarContext);
  const { car_id } = useParams();
  const carouselRef = useRef();
  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedImage(null);
  };

  const selectedCar = cars?.find((car) => car.id === car_id);
  console.log('CAR: ', selectedCar);
  const carImages = selectedCar?.data.images.flatMap((img) =>
    img.imageFormats.filter((format) => format.name === 'main'),
  );

  const handleBeforeChange = (from, to) => {
    setActiveIndex(to);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    carouselRef.current.goTo(index);
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  function getMileageInterval(mileage) {
    let lowerBound = Math.floor(mileage / 500) * 500;
    let upperBound = lowerBound + 500;

    return `${lowerBound} - ${upperBound}`;
  }

  if (!selectedCar) {
    return <div>Loading...</div>; // or some other loading component
  }

  return (
    <AnimationRevealPage>
      <HeaderContainer>
        <Header links={NAVLINKS} />
      </HeaderContainer>
      <Content2Xl>
        <Modal
          open={visible}
          onCancel={handleClose}
          footer={null}
          centered
          width="100%"
          bodyStyle={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
          }}
          wrapClassName="full-screen-modal"
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Modal>
        <PageHeader
          className="site-page-header"
          title="Tillbaka"
          subTitle=""
          onBack={handleGoBack}
        />
        <StyledRow>
          <CarouselContainer sm={24} md={16}>
            <LeftButton onClick={handlePrev}>
              <IoIosArrowBack />
            </LeftButton>
            <Carousel
              effect="fade"
              dots
              beforeChange={handleBeforeChange}
              ref={carouselRef}
            >
              {carImages.map((image, index) => (
                <CarouselImageContainer
                  active={index === activeIndex}
                  key={index}
                >
                  <CarouselImage
                    loading="lazy"
                    src={image.url}
                    alt={`Image ${index}`}
                  />
                </CarouselImageContainer>
              ))}
            </Carousel>
            <RightButton onClick={handleNext}>
              <IoIosArrowForward />
            </RightButton>
          </CarouselContainer>
          <CarouselInfoContainer sm={24} md={7}>
            <Title>
              {selectedCar.data.make} {selectedCar.data.model}
            </Title>
            <Subtitle>{selectedCar.data.modelRaw}</Subtitle>
            <StyledDivider />
            <Subtitle>Pris</Subtitle>
            <PriceTag>{formatPrice(selectedCar.data.currentPrice)}</PriceTag>
            <SubmitButton onClick={goToForm}>Kontakta säljaren</SubmitButton>
          </CarouselInfoContainer>
        </StyledRow>
        <ThumbnailsRow>
          <Thumbnails sm={24} md={16}>
            {carImages.map((thumbnail, thumbnailIndex) => (
              <Thumbnail
                loading="lazy"
                key={thumbnailIndex}
                src={thumbnail.url}
                alt={`Thumbnail ${thumbnailIndex}`}
                active={thumbnailIndex === activeIndex}
                onClick={() => handleThumbnailClick(thumbnailIndex)}
              />
            ))}
          </Thumbnails>
        </ThumbnailsRow>
        <StyledCard>
          <StyledListContainer>
            <Collapse defaultActiveKey={['1']} ghost bordered={false}>
              <StyledPanel header="Fakta" key="1">
                <StyledList>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <GiFuelTank size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Bänsle</Title>
                        <Subtitle>{selectedCar.data.fuel}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <GiGearStickPattern size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Växellåda</Title>
                        <Subtitle>{selectedCar.data.gearBox}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <VscMilestone size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Miltal</Title>
                        <Subtitle>
                          {getMileageInterval(selectedCar.data.milage)}
                        </Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <GiCarWheel size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Modellår</Title>
                        <Subtitle>{selectedCar.data.modelYear}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <GiSteeringWheel size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Biltyp</Title>
                        <Subtitle>{selectedCar.data.bodyType}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <AiOutlineSetting size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Färg</Title>
                        <Subtitle>{selectedCar.data.color}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <MdModelTraining size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Datum i trafik</Title>
                        <Subtitle>
                          {
                            new Date(selectedCar.data.regDate)
                              .toISOString()
                              .split('T')[0]
                          }
                        </Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <DingtalkOutlined style={{ fontSize: '24px' }} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Märke</Title>
                        <Subtitle>{selectedCar.data.make}</Subtitle>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row align="middle">
                      <Col span={4}>
                        <GiSpeedometer size={24} />
                      </Col>
                      <Col span={20}>
                        <Title level={5}>Modell</Title>
                        <Subtitle>
                          {selectedCar.data.make} {selectedCar.data.model}
                        </Subtitle>
                      </Col>
                    </Row>
                  </li>
                </StyledList>
              </StyledPanel>
            </Collapse>
          </StyledListContainer>
          <StyledDivider />
          <StyledListContainer>
            <Collapse defaultActiveKey={'1'} ghost bordered={false}>
              <StyledPanel header="Utrustning" key="1">
                <StyledBulletList>
                  {selectedCar.data.equipment.map((equipment, index) => {
                    return <li key={index}>{equipment}</li>;
                  })}
                </StyledBulletList>
              </StyledPanel>
            </Collapse>
          </StyledListContainer>
          <StyledDivider />
          <Descriptions title="Beskrivning" layout="vertical" ghost>
            <Descriptions.Item>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedCar.data.description,
                }}
              />
            </Descriptions.Item>
          </Descriptions>
        </StyledCard>
        <ContactUsContainer ref={formRef}>
          <ContactUsForm />
        </ContactUsContainer>
      </Content2Xl>
      <Footer />
    </AnimationRevealPage>
  );
};

export default CarDetails;
